import availableLocales from "./available-locales.json";
import {
  isPluralElement,
  isSelectElement,
  MessageFormatElement,
  parse,
  PluralOrSelectOption
} from "@formatjs/icu-messageformat-parser";
import _, { cloneDeep } from "lodash";
import { printAST } from "@formatjs/icu-messageformat-parser/printer";
import { hoistSelectors } from "@formatjs/icu-messageformat-parser/manipulator";

export const AVAILABLE_LOCALES_PRETTY: { locale: string; pretty: string; en_pretty: string }[] = [
  {
    locale: "en",
    pretty: "English",
    en_pretty: "English"
  },
  {
    locale: "en-US",
    pretty: "English (United States)",
    en_pretty: "English (United States)"
  },
  // {
  //   locale: "fr",
  //   pretty: "Français",
  //   en_pretty: "French"
  // },
  // {
  //   locale: "de",
  //   pretty: "Deutsch",
  //   en_pretty: "German"
  // },
  {
    locale: "it",
    pretty: "Italiano",
    en_pretty: "Italian"
  },
  { locale: "es", pretty: "Español", en_pretty: "Spanish" },
  { locale: "id", pretty: "Bahasa Indonesia", en_pretty: "Indonesian" }
];
const SHOULD_PARSE_REGEX = /{.*(plural,|select,)/;

export const getMessageId = (p: { defaultMessage: string; description?: string }) => {
  let defaultMessage = p.defaultMessage;
  // We have to do this because when we generate the messageIds, we flatten the special types (plural, select, etc),
  // so we have to match that flatten in order to match the messageId
  if (p.defaultMessage.match(SHOULD_PARSE_REGEX)) {
    defaultMessage = printAST(hoistSelectors(parse(p.defaultMessage)));
  }
  return djb2(p.description ? p.description + "#" + defaultMessage : defaultMessage);
};

//Stupid simple but fast djb2 string hash https://github.com/yocontra/djb2/blob/master/index.js
function djb2(str: string) {
  let prev = 5381;
  for (let i = 0; i < str.length; i++) {
    prev = (prev << 5) + prev + str[i]!.charCodeAt(0);
  }

  return Math.abs(prev).toString();
}

export const AVAILABLE_LOCALES_UNDERSCORE: Record<string, string> = availableLocales;

export const AVAILABLE_LOCALES_ARR_DASH = Object.values(AVAILABLE_LOCALES_UNDERSCORE).map(a => a.replace(/_/g, "-"));
