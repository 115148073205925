import { fetchMessages, logError, getBestFitLocale, createFormatJSWrapper, pseudoLocalize, normalizeLocale } from "./utils";
import { CacheInterface, Message } from "./types";
import { AVAILABLE_LOCALES_ARR_DASH, getMessageId } from "./common";
import { commonMessages, CommonMessagesKeys } from "./common-messages";
import { IntlShape, createFormatters, createIntl } from "@formatjs/intl";
import _ from "lodash";
import moment from "moment";
export { IntlErrorCode } from "@formatjs/intl";
export { AVAILABLE_LOCALES_PRETTY } from "./common";
export { Message } from "./types";
export { normalizeLocale, getBestFitLocale } from "./utils";

const i18nCache: Record<string, CacheInstance> = {};

type CacheInstance = {
  messages: Record<string, string>;
  formatjs: IntlShape<any>;
  bestFitLocale: string;
};

const notFoundCacheInstance = createIntl({
  locale: "en",
  messages: new Proxy(
    {},
    {
      get(__, prop) {
        return prop;
      },
      has() {
        return true;
      },
      getOwnPropertyDescriptor(__, prop) {
        return {
          enumerable: true,
          configurable: true,
          value: prop
        };
      }
    }
  )
});

const fallbackCacheInstance: CacheInstance = {
  messages: {},
  formatjs: createFormatJSWrapper("en-US", {}),
  bestFitLocale: "en-US"
};

let currentClientLocale = "";

export function initializeI18n(p: { locale: string }) {
  try {
    setCurrentLocale(p.locale);
  } catch (e) {
    console.error("Problem initializing locale!");
    console.error(e);
    setCurrentLocale("en-US");
  }
}

/** Only necessary on client devices. When called on the server, the messages will be automatically loaded */
export function setCurrentLocale(locale: string) {
  translateCache.clear();
  locale = normalizeLocale(locale);
  currentClientLocale = locale;
}

const translateCache = new Map<string, string>();
export function translate(p: Message, vars?: Record<string, string | number>) {
  if (process.env["NODE_ENV"] === "development" && typeof window === "undefined" && !p.serverLocale) {
    throw new Error(`Must define serverLocale on the server! Unable to translate defaultMessage ${p.defaultMessage}`);
  }

  const baseCacheKey = p.defaultMessage + p.description + p.serverLocale;
  const cacheKey = vars ? JSON.stringify(vars) + baseCacheKey : baseCacheKey;

  if (!translateCache.get(cacheKey)) {
    const messageId = getMessageId(p);
    const args = { ...p, id: messageId };
    const { messages, formatjs } = getCachedInstance(p);

    let msg: string;
    if (!messages[messageId]) {
      //If the message cannot be found, this is way more performant than formatjs.formatMessage
      const str = notFoundCacheInstance.formatMessage({ ...p, id: p.defaultMessage }, vars);
      if ((global as any).OLLIE_TESTING_ENV_ENABLED) {
        msg = pseudoLocalize(str);
      } else {
        msg = str;
      }
    } else {
      msg = formatjs.formatMessage(args, vars) ?? "";
    }

    //I hate trimming this here, but formatjs adds an empty space for empty variables. This fixes the extra whitespace bug in most cases
    translateCache.set(cacheKey, msg.trim());
  }

  return translateCache.get(cacheKey) as string;
}

export const translateServer = translate as {
  (p: Message & { serverLocale: string }, vars?: Record<string, string | number>): string;
  common: (serverLocale: string) => Record<CommonMessagesKeys, string>;
};

translate.common = new Proxy(
  //But on the server, you can pass a named locale to translate.common by calling it as a function and THEN accessing the common keys
  (serverLocale: string) => {
    return new Proxy(
      {},
      {
        get: (__, key: CommonMessagesKeys) => {
          const msg = { ...commonMessages[key], serverLocale };
          return translate(msg);
        }
      }
    );
  },
  {
    //Typically, you just access the properties of translate.common
    get: (__, key: CommonMessagesKeys) => {
      return translate(commonMessages[key]);
    }
  }
) as Record<CommonMessagesKeys, string> & ((serverLocale: string) => Record<CommonMessagesKeys, string>);

export function getCurrentLocale() {
  return currentClientLocale;
}

export function defineMessages<K extends keyof any, T = Message, U extends Record<K, T> = Record<K, T>>(p: U): U {
  return p;
}

export function defineMessage(p: Message): Message {
  return p;
}

defineMessage.common = new Proxy({} as Record<CommonMessagesKeys, Message>, {
  get: (__, key: CommonMessagesKeys) => {
    return defineMessage(commonMessages[key]);
  }
});

/****
 *  Translates a number like 13 to 13th, or 1 to 1st
 */
export function translateNumberToAbbreviatedOrdinal(number: number, serverLocale?: string) {
  return translate(
    {
      defaultMessage: `{number, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}`,
      description: "Ordinal numbers like 1st, 13th, 122nd",
      serverLocale
    },
    { number }
  );
}

//Note: different instances of moment will sometimes cause annoying type incompatibilities. Therefore, we "trick" typescript by telling this to accept any object with an isLeapYear function (aka a momentjs object).
export type DateInput = { toDate: () => Date; utcOffset: () => number } | Date | string | number;

//NOTE: The function names are US english approximations of what the localized format is to enable easier developer lookup.
export const dateFormatters = {
  /**
   * E.g. 8:30 PM
   */
  t_tt_a: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { hour: "numeric", minute: "numeric" }, serverLocale),
  /**
   * E.g. 09/04/1986
   */
  mm_dd_yyyy: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { month: "2-digit", day: "2-digit", year: "numeric" }, serverLocale),
  /**
   * E.g. 9/4/1986
   */
  m_d_yyyy: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { month: "numeric", day: "numeric", year: "numeric" }, serverLocale),
  /**
   * E.g. 9/4/86
   */
  m_d_yy: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { month: "numeric", day: "numeric", year: "2-digit" }, serverLocale),
  /**
   * E.g. September 4, 1986
   */
  mmmm_d_yyyy: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { month: "long", day: "numeric", year: "numeric" }, serverLocale),
  /**
   * E.g. September 1986
   */
  mmmm_yyyy: (date: DateInput, serverLocale?: string) => formatDateHelper(date, { month: "long", year: "numeric" }, serverLocale),
  /**
   * E.g. Sep 4, 1986
   */
  mmm_d_yyyy: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { month: "short", day: "numeric", year: "numeric" }, serverLocale),
  /**
   * E.g. September 4, 1986 8:30 PM
   */
  mmmm_d_yyyy_t_tt_a: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "2-digit" }, serverLocale),
  /**
   * E.g. Sep 4, 1986 8:30 PM
   */
  mmm_d_yyyy_t_tt_a: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "2-digit" }, serverLocale),
  /**
   * E.g. Thursday, September 4, 1986 8:30 PM
   */
  dddd_mmmm_d_yyyy_t_tt_a: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(
      date,
      { weekday: "long", month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "2-digit" },
      serverLocale
    ),
  /**
   * E.g. 9/4/22 8:30 PM
   */
  m_d_yy_t_tt_a: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(
      date,
      { month: "numeric", day: "numeric", year: "2-digit", hour: "numeric", minute: "2-digit" },
      serverLocale
    ),

  /**
   * E.g. Mon, Sep 4, 8:30 PM
   */
  mmm_d_t_tt_a: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(
      date,
      { weekday: "short", month: "short", day: "numeric", hour: "numeric", minute: "2-digit" },
      serverLocale
    ),

  /**
   * Thursday September 4, 1986
   */
  dddd_mmmm_d_yyyy: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { weekday: "long", month: "long", day: "numeric", year: "numeric" }, serverLocale),

  /**
   * 8:30 AM, September 4
   */
  t_tt_a_mmmm_d: (date: DateInput, serverLocale?: string) =>
    formatDateHelper(date, { hour: "numeric", minute: "2-digit", month: "long", day: "numeric" }, serverLocale),
  /**
   * E.g. 1986
   */
  year: (date: DateInput, serverLocale?: string) => formatDateHelper(date, { year: "numeric" }, serverLocale),
  /**
   * E.g. September
   */
  month: (date: DateInput, serverLocale?: string) => formatDateHelper(date, { month: "long" }, serverLocale),
  /**
   * E.g. Sep
   */
  shortMonth: (date: DateInput, serverLocale?: string) => formatDateHelper(date, { month: "short" }, serverLocale),
  /**
   * E.g. Thursday
   */
  dayOfWeek: (date: DateInput, serverLocale?: string) => formatDateHelper(date, { weekday: "long" }, serverLocale),
  /**
   * E.g. Thu
   */
  shortDayOfWeek: (date: DateInput, serverLocale?: string) => formatDateHelper(date, { weekday: "short" }, serverLocale),
  /**
   * E.g. Th
   */
  shortestDayOfWeek: (date: DateInput, serverLocale?: string) => formatDateHelper(date, { weekday: "narrow" }, serverLocale)
};

export function getAvailableLocales() {
  return AVAILABLE_LOCALES_ARR_DASH;
}

/* PRIVATE FUNCTIONS */

const formatDateHelper = _.memoize(
  (input: DateInput, format: Intl.DateTimeFormatOptions, serverLocale?: string) => {
    let locale = serverLocale ?? currentClientLocale;
    if (!locale) {
      logError(
        new Error(
          "No initialized locale detected! Ensure you call `loadOllieI18n` to set the locale and fetch messages before using Ollie i18n on client devices."
        ),
        { input, format, serverLocale }
      );
      locale = "en-US";
    }

    locale = normalizeLocale(locale);
    let date: Date;
    if (typeof input === "number") {
      date = new Date(input);
    } else if (typeof input === "string") {
      date = moment(input).toDate(); // moment parses dates in context of the local time
    } else if ("toDate" in input) {
      date = input.toDate();
    } else {
      date = input;
    }

    //If the input is a moment timezone object, make sure to preserve the timezone offset before formatting
    const offset =
      (typeof input === "object" && "utcOffset" in input ? -input.utcOffset() : date.getTimezoneOffset()) * 1000 * 60;

    const dateInUTC = new Date(date.getTime() - offset);

    return Intl.DateTimeFormat(locale, { ...format, timeZone: "UTC" }).format(dateInUTC);
  },
  (a: any, b, c) => JSON.stringify([typeof a === "object" && "utcOffset" in a ? a.utcOffset() : 0, a, b, c])
);

function getCachedInstance(p: Message): CacheInstance {
  if (detectEnv() === "node") {
    if (!p.serverLocale) {
      logError(
        "No locale passed to Ollie i18n! All calls to ollie i18n on the server must specify their locale in the function call.",
        p
      );
      return fallbackCacheInstance;
    }
  }

  return getCacheInstance(p.serverLocale || currentClientLocale);
}

function requireLocale(locale: string) {
  switch (locale) {
    case "en":
      if (1 > 0) import("./public/en.json"); //Need to import the bundle so that it get's compiled to dist by typescript...
      return require("./public/en.json");
    case "es":
      if (1 > 0) import("./public/es.json");
      return require("./public/es.json");
    case "en_US":
    default:
      if (1 > 0) import("./public/en_US.json");
      return require("./public/en_US.json");
  }
}

function getCacheInstance(locale: string): CacheInstance {
  let bestFitLocale = getBestFitLocale(locale);
  if (!i18nCache[locale]) {
    const messages = requireLocale(bestFitLocale);
    if (!messages) {
      logError(`Unable to require locale ${bestFitLocale} on the server! Using fallback.`, locale);
      i18nCache[locale] = fallbackCacheInstance;
    } else {
      i18nCache[locale] = {
        bestFitLocale,
        formatjs: createFormatJSWrapper(bestFitLocale, messages),
        messages
      };
    }
  }

  return i18nCache[locale]!;
}

function detectEnv() {
  return typeof navigator === "undefined" ? ("node" as const) : ("browserlike" as const);
}
